
// css
require('../scss/vendor.scss');

// js

window.$ = window.jQuery  = require('jquery');
window.Popper             = require('popper.js').default;
window.bootstrap          = require('bootstrap');

// js plugins
require('jquery-mousewheel');
require('./plugins/jquery.datetimepicker.full.min.js');
require('./plugins/jquery.repeater.min.js');

// datatables
require('datatables.net-bs4');
require('datatables.net-fixedheader-bs4');

// select2
require('../node_modules/select2/dist/js/select2.min.js');
require('../node_modules/select2/dist/js/i18n/fr.js');

// js-cookie
window.Cookies  = require('js-cookie');

// qrcode
window.kjua     = require('kjua');

